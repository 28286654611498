<template>
  <div>
    <v-container id="privacyPolicy" class="fill-height" tag="section">
      <Toolbar />
      <v-row justify="center">
        <v-col cols="12">
          <div class="mb-4 display-2 font-weight-bold">
            {{ termsAndConditions.title }}
          </div>
          <div v-html="getHtml(termsAndConditions.content)"></div>
        </v-col>
      </v-row>
    </v-container>
    <Footer></Footer>
  </div>
</template>

<script>
import Toolbar from "@/components/core/Toolbar.vue";
import aeoApi from "@/api/AeoAPI";
import marked from "marked";
import Footer from "@/components/core/Footer.vue";

export default {
  name: "terms-and-conditions",

  components: {
    Toolbar,
    Footer,
  },

  data() {
    return {
      termsAndConditions: {},
    };
  },

  async mounted() {
    const termsAndConditions = await aeoApi.get({
      resource: "terms-and-conditions",
    });
    this.termsAndConditions = termsAndConditions.data;
  },

  methods: {
    getHtml(content) {
      return marked.parse(content);
    },
  },
};
</script>

<style lang="sass">
#privacyPolicy
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial
    h1
      padding-bottom: 8px
      padding-top: 4px
    h2
      padding-bottom: 8px
      padding-top: 4px
</style>
