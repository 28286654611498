<!--
Component:  Home.vue

Purpose:    The main page users see when they navigate to the site. Shows three main sections:
            1. Hero: an eye-catcher that contains the sign up box
            2. Feature: A section that describes to qualities of ScopeJS
            3. Faq: The frequently asked questions

Props:      None
-->
<template>
  <div>
    <Toolbar2 />
    <div class="mx-2 mt-4 mx-md-8 mx-lg-8" style="overflow: hidden">
      <v-row>
        <v-col cols="12" lg="12" xl="8">
          <div>
            <v-card flat color="transparent">
              <!-- Cover Image -->
              <v-img
                class="mt-2 mb-2"
                :src="`${imageBase}${article.cover.url}`"
                :aspect-ratio="16 / 9"
                gradient="to top, rgba(25,32,72,.4), rgba(25,32,72,.0)"
                style="border-radius: 8px"
              ></v-img>

              <!-- Topline -->
              <v-card-text>
                <div
                  class="
                    mb-2
                    text-uppercase
                    font-weight-bold
                    body-2
                    primary--text
                  "
                ></div>
                <div class="mb-2">
                  <v-btn color="primary"> {{ article.topline }}</v-btn>
                </div>

                <!-- Title -->
                <div class="mb-2 text-h4 text-md-h2 font-weight-bold">
                  {{ article.title }}
                </div>

                <div class="mb-2 text-h6" color="secondary">
                  {{ article.subtitle }}
                </div>

                <div class="pb-4">
                  <v-btn
                    class=""
                    dark
                    fab
                    bottom
                    color="blue darken-7"
                    small
                    :href="`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}`"
                    target="_blank"
                  >
                    <v-icon>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-1"
                    dark
                    fab
                    bottom
                    color="blue"
                    small
                    :href="`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`"
                    target="_blank"
                  >
                    <v-icon>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-1"
                    dark
                    fab
                    bottom
                    color="green"
                    small
                    :href="`https://wa.me/?text=Checkout%20this%20page.%20${pageUrl}`"
                    target="_blank"
                  >
                    <v-icon>mdi-whatsapp</v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-1"
                    dark
                    fab
                    bottom
                    color="tertiary"
                    small
                    :href="`mailto:?subject=Awesomeness!&amp;body=Checkout this page!<a href='${pageUrl}'>${pageUrl}</a>`"
                    target="_blank"
                  >
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </div>
                <!-- Content -->
                <div
                  v-html="getHtml(article.content)"
                  class="text-h6 text-lg-h6 text--textbase"
                ></div>

                <div class="d-flex align-center">
                  <v-avatar small class="mr-1" size="38">
                    <img
                      :src="`${imageBase}${article.authors_photo.url}`"
                      alt=""
                      @error="setAltImg"
                    />
                  </v-avatar>
                  <div>
                    <span class="font-weight-bold text-body-2">{{
                      article.authors_name
                    }}</span>
                    <v-spacer />
                    <div class="secondary--text text--lighten-1 text-caption">
                      {{ getReadableDate(article.article_date) }}
                    </div>
                  </div>
                </div>

                <v-divider class="my-8"></v-divider>
              </v-card-text>
            </v-card>
          </div>
        </v-col>

        <!-- SIDEBAR -->
        <v-col>
          <div>
            <h3 class="py-1 text-h5 font-weight-medium">Latest from AEO</h3>

            <v-divider></v-divider>

            <div class="sidebar">
              <v-col
                v-for="(item, index) in articles"
                :key="index"
                class="py-1"
              >
                <!-- <v-col cols="12" md="6" lg="5">
                      <v-card height="100%" flat>
                        <v-img
                          :src="`${imageBase}${item.cover.url}`"
                          :aspect-ratio="16 / 9"
                          height="100%"
                        ></v-img>
                      </v-card>
                    </v-col> -->

                <v-card
                  @click="goToArticle(item.id)"
                  elevation="1"
                  class="rounded-lg"
                  outlined
                  color="light"
                >
                  <div class="item" color="white">
                    <div class="image-boxed">
                      <img
                        :src="`${imageBase}${item.cover.url}`"
                        class="rounded-lg pa-1"
                      />
                    </div>
                    <div class="content">
                      <h3>{{ item.title }}</h3>
                      <p class="news-description">
                        {{ item.description }}
                      </p>
                      <span class="font-weight-bold text-body-2">{{
                        article.authors_name
                      }}</span>
                      <div class="secondary--text text--lighten-1 text-caption">
                        {{ getReadableDate(item.article_date) }}
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Toolbar2 from "@/components/core/Toolbar.vue";
import aeoApi from "@/api/AeoAPI";
import Footer from "@/components/core/Footer.vue";
import marked from "marked";
import moment from "moment";

export default {
  name: "articles-page",
  components: {
    Toolbar2,
    Footer,
  },

  created: function () {
    this.pageUrl = window.location.href;
  },

  async mounted() {
    const articles = await aeoApi.get({ resource: "articles?_limit=10" });

    //O(nlogn) complexity max function
    const max = articles.data.sort((a, b) => b.id - a.id)[0].id;

    let lastArticleIndex = max;
    console.log(lastArticleIndex);
    const article = await aeoApi.get({
      resource: `articles/${lastArticleIndex}`,
    });

    this.$nextTick(() => {
      // execute your code
      this.article = article.data;
      this.articles = articles.data;
    });

    window.scrollTo(0, 0);
  },

  data() {
    return {
      articles: [],
      pageUrl: "",
      article: {
        topline: "",
        title: "",
        content: "",
        cover: {
          url: "",
        },
        authors_photo: {
          url: "",
        },
      },
      imageBase: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },

  methods: {
    getHtml(content) {
      return marked.parse(content);
    },
    setAltImg(event) {
      event.target.src = "../assets/images/placeholder.jpg";
    },
    getReadableDate(date) {
      return moment(new Date(date)).format("DD MMM, YYYY");
    },

    goToArticle(id) {
      this.$router.push(`article/${id}`);
    },
  },
};
</script>
<style>
.item {
  border-radius: 3px;
  /* overflow: hidden; */
  display: flex;
  cursor: pointer;
}
.content {
  padding: 24px;
}
.content > * {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-box-orient: vertical;
}
.content h3 {
  /* -webkit-line-clamp: 2; */
}
.content p {
  /* -webkit-line-clamp: 3; */
}
.image-boxed {
  flex: 0 0 35%;
  max-width: 35%;
}
.sidebar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.news-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
