<!--
Component:  Home.vue

Purpose:    The main page users see when they navigate to the site. Shows three main sections:
            1. Hero: an eye-catcher that contains the sign up box
            2. Feature: A section that describes to qualities of ScopeJS
            3. Faq: The frequently asked questions

Props:      None
-->
<template>
  <div>
    <Toolbar />
    <div class="mx-2 mt-2 mx-md-8 mx-lg-8" style="overflow: hidden">
      <v-row>
        <v-col cols="12" lg="12" xl="8">
          <div>
            <v-card flat color="transparent">
              <!-- Cover Image -->
              <v-img
                class="mt-2 mb-2"
                :src="`${imageBase}${article.cover.url}`"
                :aspect-ratio="16 / 9"
                gradient="to top, rgba(25,32,72,.4), rgba(25,32,72,.0)"
                style="border-radius: 8px"
                @error="setAltImg"
              ></v-img>

              <!-- Topline -->
              <v-card-text>
                <div
                  class="
                    mb-2
                    text-uppercase
                    font-weight-bold
                    body-2
                    primary--text
                  "
                >
                  <v-speed-dial
                    v-model="dialShare"
                    absolute
                    right
                    top
                    direction="left"
                    open-on-hover
                  >
                    <template v-slot:activator>
                      <v-btn fab bottom small color="primary">
                        <v-icon v-if="dialShare">mdi-close</v-icon>
                        <v-icon v-else>mdi-share-variant</v-icon>
                      </v-btn>
                    </template>
                    <v-btn
                      dark
                      fab
                      bottom
                      color="blue darken-7"
                      small
                      :href="`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}`"
                      target="_blank"
                    >
                      <v-icon>mdi-linkedin</v-icon>
                    </v-btn>
                    <v-btn
                      dark
                      fab
                      bottom
                      color="blue"
                      small
                      :href="`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`"
                      target="_blank"
                    >
                      <v-icon>mdi-facebook</v-icon>
                    </v-btn>
                    <v-btn
                      dark
                      fab
                      bottom
                      color="green"
                      small
                      :href="`https://wa.me/?text=${title}!&amp;${subtitle}${pageUrl}`"
                      target="_blank"
                    >
                      <v-icon>mdi-whatsapp</v-icon>
                    </v-btn>
                    <v-btn
                      dark
                      fab
                      bottom
                      color="tertiary"
                      small
                      :href="`mailto:?subject=${title}!&amp;body=${subtitle}<a href='${pageUrl}'>${pageUrl}</a>`"
                      target="_blank"
                    >
                      <v-icon>mdi-email</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </div>
                <div class="mb-2">
                  <v-btn color="primary" small> {{ article.topline }}</v-btn>
                </div>

                <!-- Title -->
                <div class="mb-2 text-h4 text-md-h2 font-weight-bold">
                  {{ article.title }}
                </div>

                <div class="mb-2 text-h5" color="secondary">
                  {{ article.subtitle }}
                </div>

                <div class="pb-4">
                  <v-btn
                    class=""
                    dark
                    fab
                    bottom
                    color="blue darken-7"
                    small
                    :href="`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}`"
                    target="_blank"
                  >
                    <v-icon>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-1"
                    dark
                    fab
                    bottom
                    color="blue"
                    small
                    :href="`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`"
                    target="_blank"
                  >
                    <v-icon>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-1"
                    dark
                    fab
                    bottom
                    color="green"
                    small
                    :href="`https://wa.me/?text=Checkout%20this%20page.%20${pageUrl}`"
                    target="_blank"
                  >
                    <v-icon>mdi-whatsapp</v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-1"
                    dark
                    fab
                    bottom
                    color="tertiary"
                    small
                    :href="`mailto:?subject=Awesomeness!&amp;body=Checkout this page!<a href='${pageUrl}'>${pageUrl}</a>`"
                    target="_blank"
                  >
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </div>
                <!-- Content -->
                <div
                  v-html="getHtml(article.content)"
                  class="text-h6 text-lg-h6 font-weight-medium"
                ></div>

                <div class="d-flex align-center">
                  <v-avatar small class="mr-1" size="38">
                    <img
                      :src="`${imageBase}${article.authors_photo.url}`"
                      alt=""
                      @error="setAltImg"
                    />
                  </v-avatar>
                  <div>
                    <span class="font-weight-bold text-body-2">{{
                      article.authors_name
                    }}</span>
                    <v-spacer />
                    <div class="secondary--text text--lighten-1 text-caption">
                      {{ getReadableDate(article.article_date) }}
                    </div>
                  </div>
                </div>
                <!-- <v-divider class="my-8"></v-divider> -->
              </v-card-text>
            </v-card>
          </div>
        </v-col>

        <!-- SIDEBAR -->
        <v-col>
          <div>
            <div>
              <div>
                <h3 class="py-2 text-h5 font-weight-medium">Latest from AEO</h3>

                <v-divider></v-divider>

                <div class="sidebar">
                  <v-row
                    v-for="(item, index) in articles"
                    :key="index"
                    class="py-1"
                  >
                    <!-- <v-col cols="12" md="6" lg="5">
                      <v-card height="100%" flat>
                        <v-img
                          :src="`${imageBase}${item.cover.url}`"
                          :aspect-ratio="16 / 9"
                          height="100%"
                        ></v-img>
                      </v-card>
                    </v-col> -->

                    <v-col>
                      <v-card
                        @click="goToArticle(item.id)"
                        elevation="1"
                        outlined
                        color="light"
                      >
                        <!-- <v-btn depressed color="accent" small>TRAVEL</v-btn> -->
                        <div class="item" color="white">
                          <div class="image-boxed">
                            <img :src="`${imageBase}${item.cover.url}`" />
                          </div>

                          <div class="content">
                            <h3>{{ item.title }}</h3>
                            <p class="news-description">
                              {{ item.description }}
                            </p>
                            <span class="font-weight-bold text-body-2">{{
                              item.authors_name
                            }}</span>
                            <div
                              class="
                                secondary--text
                                text--lighten-1 text-caption
                              "
                            >
                              {{ getReadableDate(item.article_date) }}
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Toolbar from "@/components/core/Toolbar";
import aeoApi from "@/api/AeoAPI";
import Footer from "@/components/core/Footer";
import marked from "marked";
import moment from "moment";
export default {
  components: {
    Toolbar,
    Footer,
  },

  async mounted() {
    const articleId = this.$route.params.id;
    const article = await aeoApi.get({ resource: `articles/${articleId}` });
    const articles = await aeoApi.get({ resource: "articles?_limit=6" });
    this.$nextTick(() => {
      // execute your code
      this.article = article.data;
      this.articles = articles.data;
    });
    window.scrollTo(0, 0);
  },

  created: function () {
    this.pageUrl = window.location.href;
  },

  data() {
    return {
      pageUrl: "",
      dialShare: false,
      articles: [],
      article: {
        topline: "",
        title: "",
        content: "",
        cover: {
          url: "",
        },
        authors_photo: {
          url: "",
        },
      },
      imageBase: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },

  metaInfo() {
    return {
      title: `${this.article.title}`,
      meta: [
        {
          name: "description",
          content: `${this.article.content}`,
        },
        { property: "og:title", content: this.article.title },
        { property: "og:site_name", content: "www.customs.gov.lk" },
        {
          property: "og:description",
          content: this.article.content,
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: window.location.href,
        },
        {
          property: "og:image",
          content: `${this.imageBase}${this.article.cover.url}`,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
      ],
    };
  },
  methods: {
    getHtml(content) {
      return marked.parse(content);
    },
    setAltImg(event) {
      event.target.src = "../assets/images/placeholder.jpg";
    },
    getReadableDate(date) {
      return moment(new Date(date)).format("DD MMM, YYYY");
    },

    goToArticle(id) {
      this.$router.push(`${id}`);
    },
  },
};
</script>
<style>
.item {
  border-radius: 3px;
  /* overflow: hidden; */
  display: flex;
  cursor: pointer;
}

.news-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.content {
  padding: 24px;
}
.content > * {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-box-orient: vertical;
}
.content h3 {
  -webkit-line-clamp: 2;
  padding-bottom: 1%;
}
.content p {
  -webkit-line-clamp: 3;
}
.image-boxed {
  flex: 0 0 35%;
  max-width: 35%;
}
.sidebar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.row + .row {
  margin-top: 0px;
}
</style>
