<template>
  <div>
    <v-container id="privacyPolicy" class="fill-height" tag="section">
      <Toolbar />
      <v-row justify="center">
        <v-col cols="12">
          <div class="mb-4 display-2 font-weight-bold">
            {{ privacyPolicy.title }}
          </div>
          <div v-html="markdownToHtml"></div>
        </v-col>
      </v-row>
    </v-container>
    <Footer></Footer>
  </div>
</template>

<script>
import Toolbar from "@/components/core/Toolbar.vue";
import aeoApi from "@/api/AeoAPI";
import marked from "marked";
import Footer from "@/components/core/Footer.vue";

export default {
  name: "privacy-policy",

  components: {
    Toolbar,
    Footer,
  },

  data() {
    return {
      privacyPolicy: {},
    };
  },

  computed: {
    markdownToHtml() {
      return marked(this.privacyPolicy.content);
    },
  },
  async mounted() {
    const privacyPolicy = await aeoApi.get({ resource: "privacy-policy" });
    this.privacyPolicy = privacyPolicy.data;
  },

  methods: {
    // getHtml(content) {
    //   return marked(this.input, { sanitize: true });
    // },
  },
};
</script>

<style lang="sass">
#privacyPolicy
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial
    h1
      padding-bottom: 8px
      padding-top: 4px
    h2
      padding-bottom: 8px
      padding-top: 4px
</style>
